import React, { useState, useEffect } from 'react'
//import * as ReactDOM from "react-dom";
import useDownloader from 'react-use-downloader';
import 'semantic-ui-css/semantic.min.css';
import axios from 'axios';
import {
    Modal,
    Header,
    Image
} from 'semantic-ui-react'

import MainAppView from './components/MainAppView';

import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.css';

function DeliveryApp(){
    //const contextRef = createRef();
    // eslint-disable-next-line
    const {error, download} = useDownloader();
    const [downloadApicallStatus, setdownloadApicallStatus] = useState(false);
    const [updateDownloadStatusEndpoint, setUpdateDownloadStatusEndpoint] = useState(false);
    const handleDownload = (fileUrl, filename) => {
      download(fileUrl, filename);
      updatePhotosDowloadStatus();
    };
    const updatePhotosDowloadStatus = async () => {
      if (!downloadApicallStatus && updateDownloadStatusEndpoint) {
        await axios
          .get(updateDownloadStatusEndpoint)
          .catch(function (error) {
            if (error.res) {
              console.log(error.res.data);
              console.log(error.res.status);
            }
          })
          .then(function (data) {
            if (data) {
              if (data.data) {
                if (data.data.status) {
                  setdownloadApicallStatus(data.data.status);
                }
              }
            }
          });
      }
    };
    //console.log('download error?', error)
    var floorplan_struct = {
        "jpeg": {
            "single_floor":{
                "dimensions": [],
                "plain": []
            },
            "combined":{
                "dimensions": [],
                "plain": []
            }
        },
        "svg": {
            "single_floor": [],
            "combined": []
        },
        "pdf": {
            "single_floor": [],
            "combined": []
        }
    }
    var brochures_struct = {
        "pdf": [],
        "jpg": []
    }
    var franchise_struct = {
        'code': '',
        'image': '',
        'contact_name': '',
        'contact_title': '',
        'description': '',
        'office_phone': '',
        'office_email': '',
        'office_url': '',
        'office_city': '',
        'office_state': '',
        'office_zip': '',
    }

    var property_struct = {
        'address': '',
        'citystatezip': '',
        'cover_image': '',
        'unbranded_link': '',
        'branded_link': ''
    }

    const [deliveryID, setDeliveryID] = useState([]);
    const [propertyInfo, setPropertyInfo] = useState(property_struct);
    const [franchiseInfo, setFranchiseInfo] = useState(franchise_struct);
    // eslint-disable-next-line
    const [homeID, setHomeID] = useState([]);
    const [deliveryPhotosFull, setDeliveryPhotosFull] = useState(Array);
    const [deliveryPhotosWeb, setDeliveryPhotosWeb] = useState(Array);
    const [deliveryFloorPlans, setDeliveryFloorPlans] = useState(floorplan_struct);
    const [billingDetails, setBillingDetails] = useState(Array);
    const [deliveryTours, setDeliveryTours] = useState(Array);
    const [deliveryVideos, setDeliveryVideos] = useState(Array);
    const [brochures, setBrochures] = useState(brochures_struct);
    const [retrieveStatus, setRetrieveStatus] = useState([]);
    const [deliveryZips, setDeliveryZips] = useState(Array);
    const [refetch, setRefetch] = useState();

    useEffect(() => {
        getDeliveryData();
        //getPaymentStatus();
        const handleContextMenu = (e) => {
            e.preventDefault()
        }
        document.addEventListener("contextmenu", handleContextMenu);
    }, [refetch]);
    const getDeliveryData = async () => {
        setRetrieveStatus(true);
        const encoded_id = window.location.href.split('/').pop()
        const response = await axios.get('https://www.hommati.com/photodelivery_contents/' + encoded_id)
        .catch(function(error){
            if (error.response){
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                setDeliveryID(false)
            }
        });
        if (response.data.contents === "False"){
            console.log('no delivery found...', response.data.contents);
            await setRetrieveStatus(false);
            setDeliveryID(false)
        } else {
            await setRetrieveStatus(true);
        }
        if (response.data.contents !== "False"){
            let video_list = [];
            if (response.data.contents.video.length > 0){
                video_list = response.data.contents.video
            } else if (response.data.contents.hasOwnProperty('video_link') && response.data.contents.video_link !== '') {
                video_list.push(response.data.contents.video_link)
            }
            //console.log('debug!!!  ', response.data.contents);
            setDeliveryID(response.data.contents.photodelivery_id)
            setHomeID(response.data.home_list_id)
            setPropertyInfo(response.data.contents.property_info)
            setFranchiseInfo(response.data.contents.franchise_info)
            setDeliveryPhotosFull(response.data.contents.photos.print_resolution)
            setDeliveryPhotosWeb(response.data.contents.photos.web_resolution[0])
            setDeliveryFloorPlans(response.data.contents.floor_plans)
            setDeliveryTours(response.data.contents.tours)
            setDeliveryVideos(video_list)
            setBrochures(response.data.contents.brochure)
            setDeliveryZips(response.data.contents.zip_files)
            // get payment status...
            setBillingDetails(response.data.contents.billing_details)
            //getPaymentStatus(response.data.contents.photodelivery_id)
            setdownloadApicallStatus(response.data.contents.billing_details.status_photos_downloaded)
            setUpdateDownloadStatusEndpoint(response.data.contents.billing_details.update_download_status_and_mail_to_franchise_link)
        } else {
            setDeliveryID(false)
        }
        
    }
    if (deliveryID === false){
        return (
            <Modal open={true}>
                <Modal.Header>You have followed an invalid link</Modal.Header>
                <Modal.Content image>
                    <Image href='https://www.hommati.com' src='https://static-assets.hommati.com/hommati-logo-svg-cropped.svg' size='medium' wrapped />
                    <Modal.Description>
                        <Header>The link you have followed is invalid</Header>
                        <p>
                            You may have copied an incorrect link from your email.  Please click the link and try again.
                        </p>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    } else {
        return (
            <div>
                <MainAppView 
                    download={handleDownload} 
                    viewer={Viewer} 
                    deliveryID={deliveryID}
                    homeID={homeID}
                    property_info={propertyInfo}
                    franchise_info={franchiseInfo}
                    deliveryPhotosFull={deliveryPhotosFull}
                    deliveryPhotosWeb={deliveryPhotosWeb}
                    deliveryFloorPlans={deliveryFloorPlans}
                    billingDetails={billingDetails}
                    brochures={brochures}
                    videos={deliveryVideos}
                    tours={deliveryTours}
                    retrieveStatus={retrieveStatus}
                    zip_files={deliveryZips}
                    setRefetch={setRefetch}
                    updatePhotosDowloadStatus={updatePhotosDowloadStatus}
                />
            </div>
        );
    }
}

export default DeliveryApp;